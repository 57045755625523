<template>
  <div class="program-process">
    <CurveSection color="secondary" top="true">
      <HeroSection
        title="Application & Program Process"
        detail="SanThit has been designed to provide the most inclusive and
          educational application experience possible. 98% of previous
          applicants stated that this application process helped them improve
          their business understanding (whether they were seleced or not). Read
          more below:"
        :image="require('../assets/program_process.png')"
      />
    </CurveSection>
    <CurveSection color="white">
      <StorySection title="The Application" :items="process" />
    </CurveSection>

    <CurveSection color="primary">
      <div id="the-program">
        <h2 class="title" data-content="The Program">The Program</h2>
        <h3 class="title mt-3" data-content="Village Capital Curriculum">
          Village Capital Curriculum
        </h3>
        <p>
          Over an intensive 4-5 weeks you'll be guided through a world-renowed
          startup curriculum online involving taught classes, group work,
          workbooks and more.
        </p>
        <p>
          Toward the end of the course you and your peer group will help select
          the seven companies who will go through to the next stage.
        </p>
        <h3 class="title mt-3" data-content="Sprints and Funding">
          Sprints and Funding
        </h3>
        <p>
          The seven selected companies will be awarded USD 5K each and will work
          with SanThit to develop 6-8 week projects ("sprints"). SanThit will
          provide implementation support throughout the sptints. After 8 weeks,
          the company founders will pitch to a panel; four companies will go to
          the next round.
        </p>
        <p>
          The four companies be awarded USD 10K each and will develop new
          sprints, following 8 weeks of implementation, the founders will again
          pitch to the panel; two companies will go to the final round.
        </p>
        <p>
          In the final round two companies will receive USD 15,000 and gain
          support to implement new sprints. Our aim is through these sprints you
          will gain traction and exprience to showcase to support your
          fundraising efforts.
        </p>
      </div>
    </CurveSection>
    <CurveSection color="white">
      <h2 class="title-secondary mb-3" data-content="Who Should Apply">
        Who Should Apply
      </h2>
      <p>You are one of the following:</p>
      <p>
        a startup founder pre-funding looking to grow your business and raise
        investment
      </p>
      <p>
        a startup founder with less than USD 50K funding and &lt; USD 15K cash
        balance looking to grow your business and raise investment
      </p>
      <p>
        an aspiring entrepreneur in the making with drive and commitment to
        launch a new business
      </p>
      <p>
        SanThit Accelerator is designed to be inclusive and to promote and
        catalyse gender equality. We strongly encourage women to apply and
        connect with us as we take steps toward a more inclusive
        entrepreneurship sector.
      </p>
      <div class="image-spacing"></div>
      <div class="should-u-apply-image-container">
        <img
          src="@/assets/should-u-apply.png"
          class="should-u-apply-image"
          alt="should u apply image"
        />
      </div>
    </CurveSection>
    <!-- <CurveSection color="secondary">
      <RocketSection title="Get started today!" />
    </CurveSection> -->
    <Footer color="white" />
  </div>
</template>

<script>
import HeroSection from "@/components/HeroSection.vue";
// import RocketSection from "@/components/RocketSection.vue";
import StorySection from "@/components/StorySection.vue";
import CurveSection from "@/components/CurveSection.vue";
import Footer from "@/components/Footer.vue";
import process from "@/data/process.json";

export default {
  name: "Program Process",
  created() {
    if (this.$store.state.isMenuOpen) this.$store.commit("toggleMenuOpen");
    document.title = this.$router.currentRoute.value.meta.title;
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  data() {
    return {
      process,
    };
  },
  components: {
    HeroSection,
    // RocketSection,
    StorySection,
    CurveSection,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/definations.scss";
@import "@/scss/programProcess.scss";
</style>
